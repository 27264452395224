import { Component, OnInit, ViewChild} from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { take, startWith, map } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import { GlobalState } from "src/app/store/states/global.state";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {ActivatedRoute,Router} from "@angular/router";
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  DateAdapter,
} from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SharedService } from "src/app/services/service.index";
import { NewUserComponent } from "src/app/modals/new-user/new-user.component";
import { NewFieldComponent } from "src/app/modals/new-field/new-field.component";

// Store
import * as fields from "src/app/store/selectors/fields.selectors";

import {
  FieldsGetAllAction,
  FieldsDeleteAction,
  FieldsEnabledAction,
  FieldsGetAction,
} from "src/app/store/actions/fields.actions";
import { FieldsParams } from "src/app/models/fields/fields-params";

// Third libreries
import Swal from "sweetalert2";
import * as moment from "moment";

//Services
import { FieldsService } from "src/app/services/service.index";

// Modals
import { MatDialog } from "@angular/material/dialog";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "DD-MM-YY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-fields",
  templateUrl: "./fields.component.html",
  styleUrls: ["./fields.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FieldsComponent implements OnInit {
  // Actions Datatable
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  // Datatables
  displayedColumns: string[] = ["name", "enabled", "actions"];
  dataSource: MatTableDataSource<any>;

  dateFromValue: any;
  dateUntilValue: any;
  statues: any[] = [];

  // Fields
  loadingFields?: boolean = true;
  errorFields?: boolean;
  public errorFields$?: Observable<boolean>;

  // Store
  private subscription: Subscription = new Subscription();

  // Others
  actionModal: any = {
    edit: "edit",
    new: "new",
  };

  originalData: any;
  searchIn: number;
  selectedStatues: any;
  filterValue: "";

  constructor(
    public router: Router,
    private _sharedService: SharedService,
    private _fieldsService: FieldsService,
    public store: Store<GlobalState>,
    public dialog: MatDialog
  ) {
    this._sharedService.changeTitile("Fields");
  }

  openDialog(actionModal: any, idFields?: any): void {
    const dialogRef = this.dialog.open(NewUserComponent, {
      width: "50%",
      data: { idFields, actionModal },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result) {
        // update datatables
      }
    });
  }

  ngOnInit() {
    // Get Fields
    // this.store.dispatch(new FieldsGetAllAction(null));
    this.store.dispatch(new FieldsGetAction(null));

    this.subscription.add(
      this.store.pipe(select(fields.selectFieldsLoading)).subscribe((data) => {
        this.loadingFields = data.loading;
      })
    );

    this.store.pipe(select(fields.selectFieldsError)).subscribe((error) => {
      this.errorFields = error;
    });

    // Get All Fields
    this.subscription.add(
      this.store.pipe(select(fields.selectFieldsLoading)).subscribe((data) => {
        if (!data.loading) {

          this.store
            .pipe(select(fields.selectFieldsResponse))
            .subscribe((response) => {
              if (response.valid && response.data.length > 0) {
                let data = response.data.map((element: any) => ({
                  ...element,
                  name: `${element.name} ${element.number}`,
                  nameOnly: element.name,
                  date: moment(element.dateCreated).format("YYYY-MM-DD"),
                }));
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.originalData = data;
              } else {
                this.dataSource = new MatTableDataSource([]);
              }
            });
        }
      })
    );
  }

  newField() {
    const dialogRef = this.dialog.open(NewFieldComponent, {
      width: "20%",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
          // After close
      }
    });
  }

  editField(field) {
    const dialogRef = this.dialog.open(NewFieldComponent, {
      width: "20%",
      data: field
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result == false){
          this.router.navigate(['/superadmin/fields'])
          .then(() => {
            window.location.reload();
          });
        }

    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  enabledConfirmation(idField: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The field will be shown to users again",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, enabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new FieldsEnabledAction(<FieldsParams>{
            data: { idField },
          })
        );
      }
    });
  }

  deleteConfirmation(idField: any) {
    Swal.fire({
      title: "Are you sure?",
      text: "The field will no longer be displayed to users",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disabled it!",
    }).then((result) => {
      if (result.value) {
        this.store.dispatch(
          new FieldsDeleteAction(<FieldsParams>{
            data: { idField },
          })
        );
      }
    });
  }
}
